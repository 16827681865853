<template>
  <v-card class="policy-card">
    <LearningPlusLogo :height="30" class="mb-8" />

    <Loading
        v-if="policyLoading"
        title="Loading policy details"
        :loading="policyLoading"
        class="pp-full-width mt-16"
    />

    <div v-if="! policyLoading && hasPreAmble ">

      <p>In this policy, ‘we’, ‘us’, ‘PeoplePlus’, and 'LearningPlus' means:</p>

      <p>PeoplePlus Group Limited. 19-20 The Triangle, Ng2 Business Park, Nottingham, NG2 1AE.</p>

      <p>PeoplePlus Group Limited is the owner of this Website and the PeoplePlus trademark and brand, and ‘you’ means the user of this Website. We reserve the right to withdraw all or part of the Website at any time.</p>
    </div>

    <vue-markdown v-if="! policyLoading" :source="currentPolicy" />
  </v-card>
</template>

<script>
import VueMarkdown from "vue-markdown";
import Loading from "@/components/common/Loading";
import LearningPlusLogo from "@/components/logos/LearningPlusLogo";
import {mapGetters, mapActions} from "vuex";

export default {
  name: "PolicyTermsConditions",
  components: { LearningPlusLogo, Loading, VueMarkdown },
  computed: {
    ...mapGetters(['policyLoading', 'currentPolicy', 'hasPreText']),
    hasPreAmble() {
      return this.hasPreText(this.$route.params.policy);
    },
  },
  methods: {
    ...mapActions(['getPolicy']),
  },
  async beforeMount() {
    try {
      await this.getPolicy(this.$route.params.policy);
    } catch(e) {
      await this.$router.replace("/not-found");
    }
  },
};
</script>
